// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "management",
    items: [
      {
        title: "Booking",
        path: PATH_DASHBOARD.booking.root,
        icon: ICONS.booking,
      },
      {
        title: "Blog",
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
      },
      // {
      //   title: "Report",
      //   path: PATH_DASHBOARD.report.root,
      //   icon: <Iconify icon="carbon:report" />,
      // },
      // {
      //   title: "user",
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   // children: [
      //   //   { title: 'User Management', path: 'user' },
      //   //   // { title: 'User Management', path: PATH_DASHBOARD.user.profile },
      //   //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //   //   // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //   //   // { title: 'edit', path: PATH_DASHBOARD.user.editById },
      //   //   // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   // ],
      // },
      // {
      //   title: "Question",
      //   path: PATH_DASHBOARD.question.root,
      //   icon: ICONS.kanban,
      // },
      // {
      //   title: "Report / Ban user",
      //   path: PATH_DASHBOARD.reportUser.root,
      //   icon: <Iconify icon="eva:person-delete-fill" />,
      // },
      // {
      //   title: "Black list",
      //   path: PATH_DASHBOARD.blackList.root,
      //   icon: (
      //     <Iconify icon="fluent-emoji-high-contrast:face-with-head-bandage" />
      //   ),
      // },
      // {
      //   title: "Role",
      //   path: PATH_DASHBOARD.role.root,
      //   icon: <Iconify icon="carbon:user-identification" />,
      // },
      // {
      //   title: "Notification",
      //   path: PATH_DASHBOARD.noti.root,
      //   icon: <Iconify icon="carbon:notification-new" />,
      // },
      // {
      //   title: "Notification history",
      //   path: PATH_DASHBOARD.noti.history,
      //   icon: <Iconify icon="icon-park-outline:history-query" />,
      // },
      {
        title: 'Meta data',
        path: PATH_DASHBOARD.metaData.root,
        icon: <Iconify icon="eva:settings-2-fill" />,
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
