import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({
  disabledLink = false,
  isFull = false,
  width = "277",
  sx,
}) {
  const logo = (
    <>
      {isFull ? (
        <Box sx={{ ...sx }}>
          <svg
            width={width}
            height="30"
            viewBox="0 0 277 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M267 27C267 28.1046 267.895 29 269 29C270.105 29 271 28.1046 271 27C271 25.8954 270.105 25 269 25C267.895 25 267 25.8954 267 27Z"
              fill="black"
            />
            <path
              d="M273 27C273 28.1046 273.895 29 275 29C276.105 29 277 28.1046 277 27C277 25.8954 276.105 25 275 25C273.895 25 273 25.8954 273 27Z"
              fill="black"
            />
            <path
              d="M261 27C261 28.1046 261.895 29 263 29C264.105 29 265 28.1046 265 27C265 25.8954 264.105 25 263 25C261.895 25 261 25.8954 261 27Z"
              fill="black"
            />
            <path
              d="M4.56 28.88H9.4L12.8 4.72H12.88L15.56 28.88H20.44L25.64 0H21.88L20.16 11.88L18.44 23.76H18.36L15.72 0H10.28L7.08 23.76H7L4.12 0H0L4.56 28.88Z"
              fill="black"
            />
            <path
              d="M27.6397 28.88H38.5197V25.52H31.6397V15.76H38.1197V12.4H31.6397V3.36H38.5197V0H27.6397V28.88Z"
              fill="black"
            />
            <path
              d="M50 24.2C50 27.8 52.52 29.36 56.2 29.36C60.96 29.36 62.72 26.76 62.72 22.24C62.72 18.76 62 17.36 58.08 13.28C54.4 9.44 54 8.24 54 6.28C54 4.04 54.76 3.12 56.4 3.12C57.96 3.12 58.64 4.08 58.64 5.72V8H62.4V5.28C62.4 3.24 61.92 2.24 61.24 1.6C60.28 0.72 58.72 0 56.56 0C52.2 0 50 2.04 50 6.76C50 10.68 52.12 13 54.68 15.56C58.84 19.68 58.96 20.48 58.96 22.92C58.96 24.8 58.44 26.24 56.32 26.24C54.72 26.24 54 25.04 54 23.12V20.76H50V24.2Z"
              fill="black"
            />
            <path
              d="M64.2047 29.12H68.2047L69.5247 21.76H75.7647L77.0847 29.12H81.0847L75.2047 0.239999H70.5647L64.2047 29.12ZM72.6447 4.48H72.7247L75.2047 18.4H70.1247L72.6447 4.48Z"
              fill="black"
            />
            <path
              d="M82.8981 29.12H86.8981V0.239999H82.8981V29.12Z"
              fill="black"
            />
            <path
              d="M93.7369 3.6H95.3369C98.7369 3.6 99.1769 5.04 99.1769 9.32V19.24C99.1769 24.2 98.6169 25.76 95.6569 25.76H93.7369V3.6ZM89.7369 29.12H95.5369C101.857 29.12 103.177 26.8 103.177 19.4V10.8C103.177 2.4 102.657 0.239999 96.1769 0.239999H89.7369V29.12Z"
              fill="black"
            />
            <path
              d="M114.52 24.2C114.52 27.8 117.04 29.36 120.72 29.36C125.48 29.36 127.24 26.76 127.24 22.24C127.24 18.76 126.52 17.36 122.6 13.28C118.92 9.44 118.52 8.24 118.52 6.28C118.52 4.04 119.28 3.12 120.92 3.12C122.48 3.12 123.16 4.08 123.16 5.72V8H126.92V5.28C126.92 3.24 126.44 2.24 125.76 1.6C124.8 0.72 123.24 0 121.08 0C116.72 0 114.52 2.04 114.52 6.76C114.52 10.68 116.64 13 119.2 15.56C123.36 19.68 123.48 20.48 123.48 22.92C123.48 24.8 122.96 26.24 120.84 26.24C119.24 26.24 118.52 25.04 118.52 23.12V20.76H114.52V24.2Z"
              fill="black"
            />
            <path
              d="M133.765 3.6H135.845C138.565 3.6 138.845 4.04 138.845 8.4C138.845 12.76 138.565 13.2 135.845 13.2H133.765V3.6ZM129.765 29.12H133.765V16.56H136.365C141.805 16.56 142.845 15.08 142.845 8.4C142.845 1.72 141.805 0.239999 136.365 0.239999H129.765V29.12Z"
              fill="black"
            />
            <path
              d="M141.303 29.12H145.303L146.623 21.76H152.863L154.183 29.12H158.183L152.303 0.239999H147.663L141.303 29.12ZM149.743 4.48H149.823L152.303 18.4H147.223L149.743 4.48Z"
              fill="black"
            />
            <path
              d="M159.481 22.2C159.481 26.68 161.121 29.36 166.161 29.36C170.801 29.36 172.041 27.04 172.041 24V20.8H168.281V22.8C168.281 25.2 167.321 26.24 165.841 26.24C164.561 26.24 163.481 25.64 163.481 22.24V8.08C163.481 4.6 163.881 3.12 165.961 3.12C167.641 3.12 168.281 4.28 168.281 6.12V8.12H172.041V5.24C172.041 2 170.201 0 166.241 0C160.801 0 159.481 3.56 159.481 7.6V22.2Z"
              fill="black"
            />
            <path
              d="M173.983 29.12H184.863V25.76H177.983V16H184.463V12.64H177.983V3.6H184.863V0.239999H173.983V29.12Z"
              fill="black"
            />
            <path
              d="M193.583 24.2C193.583 27.8 196.103 29.36 199.783 29.36C204.543 29.36 206.303 26.76 206.303 22.24C206.303 18.76 205.583 17.36 201.663 13.28C197.983 9.44 197.583 8.24 197.583 6.28C197.583 4.04 198.343 3.12 199.983 3.12C201.543 3.12 202.223 4.08 202.223 5.72V8H205.983V5.28C205.983 3.24 205.503 2.24 204.823 1.6C203.863 0.72 202.303 0 200.143 0C195.783 0 193.583 2.04 193.583 6.76C193.583 10.68 195.703 13 198.263 15.56C202.423 19.68 202.543 20.48 202.543 22.92C202.543 24.8 202.023 26.24 199.903 26.24C198.303 26.24 197.583 25.04 197.583 23.12V20.76H193.583V24.2Z"
              fill="black"
            />
            <path
              d="M207.787 29.12H211.787L213.107 21.76H219.347L220.667 29.12H224.667L218.787 0.239999H214.147L207.787 29.12ZM216.227 4.48H216.307L218.787 18.4H213.707L216.227 4.48Z"
              fill="black"
            />
            <path
              d="M226.481 29.12H230.481V0.239999H226.481V29.12Z"
              fill="black"
            />
            <path
              d="M237.319 3.6H238.919C242.319 3.6 242.759 5.04 242.759 9.32V19.24C242.759 24.2 242.199 25.76 239.239 25.76H237.319V3.6ZM233.319 29.12H239.119C245.439 29.12 246.759 26.8 246.759 19.4V10.8C246.759 2.4 246.239 0.239999 239.759 0.239999H233.319V29.12Z"
              fill="black"
            />
          </svg>
        </Box>
      ) : (
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="19"
            cy="4"
            r="4"
            transform="rotate(90 19 4)"
            fill="#B3B3B3"
          />
          <circle
            cx="19"
            cy="20"
            r="4"
            transform="rotate(90 19 20)"
            fill="#B3B3B3"
          />
          <circle
            cx="19"
            cy="32"
            r="4"
            transform="rotate(90 19 32)"
            fill="#B3B3B3"
          />
        </svg>
      )}
    </>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
