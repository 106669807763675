// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  about: "/about-us",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
  },
  booking: {
    root: path(ROOTS_DASHBOARD, "/booking/management"),
    create: path(ROOTS_DASHBOARD, "/booking/create"),
    update: path(ROOTS_DASHBOARD, "/booking/update"),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog/management"),
    create: path(ROOTS_DASHBOARD, "/blog/create"),
    update: path(ROOTS_DASHBOARD, "/blog/update"),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user/management"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    management: path(ROOTS_DASHBOARD, "/user/management"),
    detail: path(ROOTS_DASHBOARD, "/user/:id"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  role: {
    root: path(ROOTS_DASHBOARD, "/role/management"),
    create: path(ROOTS_DASHBOARD, "/role/create"),
    update: path(ROOTS_DASHBOARD, "/role/update"),
  },
  noti: {
    root: path(ROOTS_DASHBOARD, "/noti/management"),
    history: path(ROOTS_DASHBOARD, "/noti/history"),
    create: path(ROOTS_DASHBOARD, "/noti/create"),
    update: path(ROOTS_DASHBOARD, "/noti/update"),
  },
  metaData: {
    root: path(ROOTS_DASHBOARD, "/meta-data/management"),
    create: path(ROOTS_DASHBOARD, "/meta-data/create"),
    update: path(ROOTS_DASHBOARD, "/meta-data/update"),
  },
  question: {
    root: path(ROOTS_DASHBOARD, "/question/management"),
    management: path(ROOTS_DASHBOARD, "/question/management"),
    detail: path(ROOTS_DASHBOARD, "/question/:id"),
    update: path(ROOTS_DASHBOARD, "/question/update/:id"),
  },
  reportUser: {
    root: path(ROOTS_DASHBOARD, "/report-user/management"),
    detail: path(ROOTS_DASHBOARD, "/report-user/:id"),
    update: path(ROOTS_DASHBOARD, "/report-user/update/:id"),
  },
  blackList: {
    root: path(ROOTS_DASHBOARD, "/black-list/management"),
    detail: path(ROOTS_DASHBOARD, "/black-list/:id"),
    update: path(ROOTS_DASHBOARD, "/black-list/update/:id"),
  },
  report: {
    root: path(ROOTS_DASHBOARD, "/report/management"),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
