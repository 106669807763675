import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { API_URI } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  total: 0,
  userDetail: null,
  userTab: "notVerify",
  roles: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload.users;
      state.total = action.payload.total;
    },

    // GET DETAIL USER
    getUserDetailSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload;
    },

    // SET USER TAB
    setUserTab(state, action) {
      state.userTab = action.payload;
    },

    // GET ROLES
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Action
export const { setUserTab } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/user/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getUsersSuccess({
          users: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v2/admin/user/info/${id}`);
      dispatch(slice.actions.getUserDetailSuccess(response.data?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateUser(data, id) {
  try {
    const response = await axios.put(`${API_URI}/v1/user/update/${id}`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function updateUserRole(data) {
  try {
    const response = await axios.post(`${API_URI}/v1/role/set-roles`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export function getRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/admin/role/get-list`);
      dispatch(slice.actions.getRolesSuccess(response.data?.data || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
