export { default as NavSectionVertical } from "./vertical";
export { default as NavSectionHorizontal } from "./horizontal";

export function isExternalLink(path) {
  return path.includes("http");
}

export function getActive(path, pathname) {
  const splitPath = path.split("/");
  const splitPathname = pathname.split("/");
  return path ? splitPath[1] === splitPathname[1] : false;
}
