import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { API_URI } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  blogList: [],
  total: 0,
  totalPages: 1,
  detail: null,
};

const slice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setBlogDetail(state, action) {
      state.detail = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET blogList
    getBlogListSuccess(state, action) {
      state.isLoading = false;
      state.blogList = action.payload.blogList;
      state.total = action.payload.total;
      state.totalPages = action.payload.totalPages;
    },

    // GET noti detail
    getBlogDetailSuccess(state, action) {
      state.isLoading = false;
      state.detail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setBlogDetail } = slice.actions;

// ----------------------------------------------------------------------

export function getBlogList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/blog/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getBlogListSuccess({
          blogList: response.data?.data || [],
          total: response.data?.meta?.total || 0,
          totalPages: response.data?.meta?.total_pages || 1,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBlogDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/blog/get-one/${id}`);
      dispatch(slice.actions.getBlogDetailSuccess(response.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createBlog(data) {
  try {
    const response = await axios.post(`${API_URI}/v1/blog/create`, {
      ...data,
    });
    return response;
  } catch (error) {
    return { error: true, message: error?.error?.detail };
  }
}

export async function updateBlog(data, id) {
  try {
    const response = await axios.put(`${API_URI}/v1/blog/update/${id}`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return { error: true, message: error?.error?.detail };
  }
}

export async function deleteBlog(id) {
  try {
    return await axios.delete(`${API_URI}/v1/blog/delete/${id}`);
  } catch (error) {
    return { error: true, message: error?.error?.detail };
  }
}

export async function uploadImage(image) {
  const media_type = /\/([^$]+$)/.exec(image.type)?.[1];
  const content = image.content;
  const uploadParams = {
    media_type: media_type || "",
  };
  const posUpload = await createUploadLink(uploadParams);
  await upload({
    uploadUrl: posUpload?.push_url.replace("http://", "https://") || "",
    mime_type: image.type,
    content,
  });
  const response = await commitUploadLink({
    name: posUpload?.name,
    media_type,
  });
  return response?.data?.data || null;
}

async function createUploadLink({ media_type }) {
  const response = await axios.post(`${API_URI}/v1/media/pre-upload`, {
    media_type,
  });
  return response?.data?.data || null;
}

async function upload({ uploadUrl, mime_type, content }) {
  return await fetch(uploadUrl, {
    method: "PUT",
    headers: {
      "Content-Type": mime_type,
      "x-amz-acl": "public-read",
    },
    body: content,
  });
}

async function commitUploadLink({ name, media_type }) {
  return await axios.post(`${API_URI}/v1/media/pos-upload`, {
    name,
    media_type,
    type_to_crop: "cover",
  });
}
