import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { API_URI } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  roles: [],
  total: 0,
  totalPages: 1,
  roleDetail: null,
};

const slice = createSlice({
  name: "role",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET roles
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload.roles;
      state.total = action.payload.total;
      state.totalPages = action.payload.totalPages;
    },

    // GET role detail
    getRoleDetailSuccess(state, action) {
      state.isLoading = false;
      state.roleDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRoles({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/admin/role/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getRolesSuccess({
          roles: response.data?.data || [],
          total: response.data?.meta?.total || 0,
          totalPages: response.data?.meta?.total_pages || 1,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoleDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${API_URI}/v1/admin/role/get-one/${id}`
      );
      dispatch(slice.actions.getRoleDetailSuccess(response.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createRole(data) {
  try {
    const response = await axios.post(`${API_URI}/v1/admin/role/create`, {
      ...data,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateRole(data, id) {
  const response = await axios.put(`${API_URI}/v1/admin/role/update/${id}`, {
    ...data,
  });
  return response.data;
}

export async function deleteRole(id) {
  try {
    const response = await axios.delete(
      `${API_URI}/v1/admin/role/delete/${id}`
    );
    return response;
  } catch (error) {
    return error?.error;
  }
}
