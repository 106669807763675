import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import parsePhoneNumber from "libphonenumber-js";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { ERROR_MESSAGE } from "../../../config";

export default function LoginForm({ setStep, phoneNumber, setPhoneNumber }) {
  const { genOTP } = useAuth();

  const isMountedRef = useIsMountedRef();

  const LoginSchema = Yup.object().shape({
    phone_number: Yup.string().required("Phone number is required"),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: { phone_number: phoneNumber },
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const parsePhone = parsePhoneNumber(`+84${data.phone_number}`);
      if (parsePhone) {
        const response = await genOTP(parsePhone.number);
        if (response.data.status === "created") {
          setPhoneNumber(parsePhone.number);
          setStep(2);
        } else {
          setError("afterSubmit");
        }
      }
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError("afterSubmit", error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">
            {errors.afterSubmit.message || ERROR_MESSAGE}
          </Alert>
        )}
        <Box>
          <RHFTextField
            name="phone_number"
            label="Phone number"
            type="number"
          />
        </Box>

        {/* <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Send OTP
      </LoadingButton>
    </FormProvider>
  );
}
