import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { API_URI } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  notiList: [],
  total: 0,
  totalPages: 1,
  deepLinks: [],
  totalDeepLink: 0,
  categories: [],
  totalCategories: 0,
  notiDetail: null,
  notiHistoryList: [],
  totalHistory: 0,
};

const slice = createSlice({
  name: "noti",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setNotiDetail(state, action) {
      state.notiDetail = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET notiList
    getNotiListSuccess(state, action) {
      state.isLoading = false;
      state.notiList = action.payload.notiList;
      state.total = action.payload.total;
      state.totalPages = action.payload.totalPages;
    },

    // GET noti detail
    getNotiDetailSuccess(state, action) {
      state.isLoading = false;
      state.notiDetail = action.payload;
    },

    // GET deepLinks
    getDeepLinksSuccess(state, action) {
      state.isLoading = false;
      state.deepLinks = action.payload.deepLinks;
      state.totalDeepLink = action.payload.total;
    },

    // GET categories
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload.categories;
      state.totalCategories = action.payload.total;
    },

    // GET notiList history
    getNotiHistoryListSuccess(state, action) {
      state.isLoading = false;
      state.notiHistoryList = action.payload.notiList;
      state.totalHistory = action.payload.total;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setNotiDetail } = slice.actions;

// ----------------------------------------------------------------------

export function getNotiList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/booking/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getNotiListSuccess({
          notiList: response.data?.data || [],
          total: response.data?.meta?.total_rows || 0,
          totalPages: response.data?.meta?.total_pages || 1,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotiDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${API_URI}/v1/admin/notify-rule/get-one/${id}`
      );
      dispatch(slice.actions.getNotiDetailSuccess(response.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function sendNoti(data) {
  const response = await axios.post(`${API_URI}/v1/admin/notification/send`, {
    ...data,
  });
  return response;
}

export async function sendNotiByFile(data) {
  const token = localStorage.getItem("accessToken");
  const response = await fetch(
    `${API_URI}/v1/admin/notification/send-by-file`,
    {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: data,
    }
  );
  return response;
}

export async function createNoti(data) {
  try {
    const response = await axios.post(
      `${API_URI}/v1/admin/notify-rule/create`,
      {
        ...data,
      }
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateNoti(data, id) {
  const response = await axios.put(
    `${API_URI}/v1/admin/notify-rule/update/${id}`,
    {
      ...data,
    }
  );
  return response.data;
}

export async function deleteNoti(id) {
  const response = await axios.delete(
    `${API_URI}/v1/admin/notify-rule/delete/${id}`
  );
  return response;
}

export function getDeepLinks(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/metadata/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getDeepLinksSuccess({
          deepLinks: response.data?.data || [],
          total: response.data?.meta?.total_rows || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKeyCategories(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/metadata/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getCategoriesSuccess({
          categories: response.data?.data || [],
          total: response.data?.meta?.total_rows || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotiHistoryList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${API_URI}/v1/admin/noti-history/get-list`,
        {
          params,
        }
      );
      dispatch(
        slice.actions.getNotiHistoryListSuccess({
          notiList: response.data?.data || [],
          total: response.data?.meta?.total_rows || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
