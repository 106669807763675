import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import userReducer from "./slices/user";
import questionReducer from "./slices/question";
import reportUserReducer from "./slices/reportUser";
import blackListReducer from "./slices/blackList";
import roleReducer from "./slices/role";
import notiReducer from "./slices/noti";
import bookingReducer from "./slices/booking";
import blogReducer from "./slices/blog";
import settingReducer from "./slices/setting";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  user: userReducer,
  question: questionReducer,
  reportUser: reportUserReducer,
  blackListUser: blackListReducer,
  role: roleReducer,
  noti: notiReducer,
  booking: bookingReducer,
  blog: blogReducer,
  setting: settingReducer,
});

export { rootPersistConfig, rootReducer };
