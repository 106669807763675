import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import { PATH_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "/", element: <Navigate to="/booking" replace /> },
        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "booking",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.booking.root} replace />,
              index: true,
            },
            { path: "management", element: <BookingManagement /> },
            { path: "create", element: <BookingDetail /> },
            { path: "update/:id", element: <BookingDetail /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.blog.root} replace />,
              index: true,
            },
            { path: "management", element: <BlogManagement /> },
            { path: "create", element: <BlogDetail /> },
            { path: "update/:id", element: <BlogDetail /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.user.management} replace />,
              index: true,
            },
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            { path: "management", element: <UserManagement /> },
            { path: ":id", element: <DetailUser /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            // { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: "question",
          children: [
            {
              element: (
                <Navigate to={PATH_DASHBOARD.question.management} replace />
              ),
              index: true,
            },
            { path: "management", element: <QuestionManagement /> },
            { path: "update/:id", element: <QuestionUpdate /> },
            { path: ":id", element: <QuestionDetail /> },
          ],
        },
        {
          path: "role",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.role.root} replace />,
              index: true,
            },
            { path: "management", element: <RoleManagement /> },
            { path: "create", element: <RoleCreate /> },
            { path: "update/:id", element: <RoleUpdate /> },
          ],
        },
        {
          path: "noti",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.noti.root} replace />,
              index: true,
            },
            { path: "history", element: <NotiHistory /> },
            { path: "management", element: <NotiManagement /> },
            { path: "create", element: <NotiDetail /> },
            { path: "update/:id", element: <NotiDetail /> },
          ],
        },
        {
          path: "meta-data",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.metaData.root} replace />,
              index: true,
            },
            { path: "management", element: <MetaDataManagement /> },
            { path: "create", element: <MetaDataDetail /> },
            { path: "update/:id", element: <MetaDataDetail /> },
          ],
        },
        {
          path: "report-user",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.reportUser.root} replace />,
              index: true,
            },
            { path: "management", element: <ReportUserManagement /> },
            { path: ":id", element: <DetailUser /> },
          ],
        },
        {
          path: "black-list",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.blackList.root} replace />,
              index: true,
            },
            { path: "management", element: <BlackListManagement /> },
            { path: ":id", element: <DetailUser /> },
          ],
        },
        {
          path: "report",
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.report.root} replace />,
              index: true,
            },
            { path: "management", element: <ReportMangement /> },
          ],
        },
        { path: "calendar", element: <Calendar /> },
        { path: "kanban", element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: '500', element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //   ],
    // },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const BookingManagement = Loadable(
  lazy(() => import("../pages/dashboard/booking/BookingManagement"))
);
const BookingDetail = Loadable(
  lazy(() => import("../pages/dashboard/booking/Detail"))
);
const BlogManagement = Loadable(
  lazy(() => import("../pages/dashboard/blog/BlogManagement"))
);
const BlogDetail = Loadable(
  lazy(() => import("../pages/dashboard/blog/Detail"))
);
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserManagement = Loadable(
  lazy(() => import("../pages/dashboard/user/UserManagement"))
);
const QuestionManagement = Loadable(
  lazy(() => import("../pages/dashboard/question/QuestionManagement"))
);
const QuestionDetail = Loadable(
  lazy(() => import("../pages/dashboard/question/Detail"))
);
const QuestionUpdate = Loadable(
  lazy(() => import("../pages/dashboard/question/Update"))
);
const RoleManagement = Loadable(
  lazy(() => import("../pages/dashboard/role/RoleManagement"))
);
const RoleCreate = Loadable(
  lazy(() => import("../pages/dashboard/role/Create"))
);
const RoleUpdate = Loadable(
  lazy(() => import("../pages/dashboard/role/Update"))
);
const NotiManagement = Loadable(
  lazy(() => import("../pages/dashboard/noti/NotiManagement"))
);
const NotiHistory = Loadable(
  lazy(() => import("../pages/dashboard/notiHistory/NotiHistoryManagement"))
);
const NotiDetail = Loadable(
  lazy(() => import("../pages/dashboard/noti/Detail"))
);
const MetaDataManagement = Loadable(
  lazy(() => import("../pages/dashboard/metadata/MetaDataMangement"))
);
const MetaDataDetail = Loadable(
  lazy(() => import("../pages/dashboard/metadata/Detail"))
);
const ReportUserManagement = Loadable(
  lazy(() => import("../pages/dashboard/reportUser/ReportUserManagement"))
);
const ReportMangement = Loadable(
  lazy(() => import("../pages/dashboard/report/NotiHistoryManagement"))
);
const BlackListManagement = Loadable(
  lazy(() => import("../pages/dashboard/BlackList/BlackListManagement"))
);
const DetailUser = Loadable(
  lazy(() => import("../pages/dashboard/user/Detail"))
);
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));
// Main
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
