import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { API_URI } from "../../config";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  bookingList: [],
  total: 0,
  totalPages: 1,
  detail: null,
};

const slice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setBookingDetail(state, action) {
      state.detail = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET bookingList
    getBookingListSuccess(state, action) {
      state.isLoading = false;
      state.bookingList = action.payload.bookingList;
      state.total = action.payload.total;
      state.totalPages = action.payload.totalPages;
    },

    // GET noti detail
    getBookingDetailSuccess(state, action) {
      state.isLoading = false;
      state.detail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setBookingDetail } = slice.actions;

// ----------------------------------------------------------------------

export function getBookingList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/booking/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getBookingListSuccess({
          bookingList: response.data?.data || [],
          total: response.data?.meta?.total || 0,
          totalPages: response.data?.meta?.total_pages || 1,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBookingDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_URI}/v1/booking/get-one/${id}`);
      dispatch(slice.actions.getBookingDetailSuccess(response.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createBooking(data) {
  try {
    const response = await axios.post(`${API_URI}/v1/booking/create`, {
      ...data,
    });
    return response;
  } catch (error) {
    return { error: true, message: error?.error?.detail };
  }
}

export async function updateBooking(data, id) {
  try {
    const response = await axios.put(`${API_URI}/v1/booking/update/${id}`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return { error: true, message: error?.error?.detail };
  }
}

export async function deleteBooking(id) {
  try {
    return await axios.delete(`${API_URI}/v1/booking/delete/${id}`);
  } catch (error) {
    return { error: true, message: error?.error?.detail };
  }
}

export async function syncCalendar(id) {
  try {
    return await axios.get(`${API_URI}/v1/booking/calendar/${id}`);
  } catch (error) {
    return { error: true, data: error?.data, message: error?.error?.detail };
  }
}
